import React from 'react';

class PointReference extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        points:[],
    }
  }


  componentDidMount = () => {
    fetch('https://api.involvedk12.org/api/getPoints.php?school=' + this.props.school + "&ClassId=x"/* + this.props.classId/**/).then(res=>res.json()).then((result) =>{
        this.setState({
            points: result,
        });
        console.log(result);
    });
  }

  render() {
      const multiplesInDiv = (
        <div>
            <table>
                <thead></thead>
                <tbody>
                    <tr><th>Points</th><th>Title</th><th>Description</th></tr>
                    {this.state.points.map(point => {
                        return(<tr key={Math.random(50000)}>
                            <td key={Math.random(50000)}>{point.points}</td>
                            <td key={Math.random(50000)}>{point.activity}</td>
                            <td key={Math.random(50000)}>{point.description}</td>
                        </tr>)
                    })}
                </tbody>
                <tfoot></tfoot>
            </table>
        </div>
        );

        return multiplesInDiv;
    }
}
  
export default PointReference;