import React from 'react';
import GradePicker from "./gradepicker";
import ClassDropdown from "./classdropdown";
import PointPicker from "./pointpicker";
import StudentField from "./studentfield";
import ReactLoading from "react-loading";

class PointGiver extends React.Component {
    constructor(props) {
        super(props);

        this.setCourse = this.setCourse.bind(this);
        this.setGrade = this.setGrade.bind(this);
        this.setPoints = this.setPoints.bind(this);
        //this.updateStudents = this.updateStudents.bind(this);

        this.state = {
            grade: 0,
            course: 0,
            point: 0,
            studentsArr: [],
            selectedStudents: [],
            courses: [],
            UserId: this.props.userId,
            schoolOptions: [],
            school: 0,
            isLoading: false,
        }
        
        this.state.school = this.props.school;
    }

    componentDidMount = () => {
        this.updateCourses(99);
        fetch("https://api.involvedk12.org/api/getSchools.php").then(res => res.json()).then(result => {
            console.log(result);
            this.setState({
                schoolOptions: result
            })
        });
    }

    setPoints = e => {
        const passedValue = e.target.value;
        this.setState({
            point: passedValue,
        });
    }

    setCourse = e => {
        const passedValue = parseInt(e.target.value);
        this.setState({
            course: passedValue,
        });
        this.setStudents(passedValue);
    }

    updateCourses = (passedValue) => {
        if (this.props.auth === 52) {
            fetch('https://api.involvedk12.org/api/getClasses.php?userId=' + this.props.userId + "&auth=" + this.props.auth)
                .then(res => res.json())
                .then(result => {
                    this.setState({
                        courses: result,
                        studentArr: [],
                    })
                });
        } else {
            fetch('https://api.involvedk12.org/api/getClasses.php?school=' + this.state.school + '&grade=' + passedValue)
                .then(res => res.json())
                .then(result => {
                    this.setState({
                        courses: result,
                        studentArr: [],
                    })
                });
        }
        console.log(this.state);
    }

    setStudents = (passedValue) => {
        this.setState({
            isLoading: true,
        })
        fetch('https://api.involvedk12.org/api/getStudents.php?class=' + passedValue)
            .then(res => res.json())
            .then(result => {
                this.setState({
                    studentsArr: result,
                    isLoading:false,
                })
            });
        this.setState({
            isLoading: false,
        })
    }

    setGrade = e => {
        const passedValue = parseInt(e.target.value);
        this.setState({
            grade: passedValue,
            studentArr: [],
            course: 0,
        });
        this.updateCourses(passedValue);
        console.log(this.state);
    }


    giveThePoints = () => {
        this.setState({
            isLoading:true,
        })
        let studentArr = document.getElementsByName('studentsSelected');
        let points = document.getElementById('pointIdSelect').value;
        let studentIdArray = [];
        studentArr.forEach(element => {
            if (element.checked) {
                studentIdArray.push(element.id);
            }
        });
        fetch('https://api.involvedk12.org/api/updateGroupPoints.php?users=' + JSON.stringify(studentIdArray) + '&points=' + points).then(
            this.setState({
                selectedStudents: [],
            })).then(() => {
                this.setStudents(this.state.course);
                this.setState({
                    isLoading:false,
                })
            });
    }
    giveSchoolPoints = () => {
        this.setState({
            isLoading:true,
        })
        if(this.state.school > 0)
        {
            let points = document.getElementById('pointIdSelect').value;
            fetch('https://api.involvedk12.org/api/updateGroupPoints.php?school=' + this.state.school + '&points=' + points).then(result => {
                console.log(result);
                this.setStudents(this.state.course);
                this.setState({
                    isLoading:false,
                });
            });      
        }else{
            alert("Please Select A School");
        }
         
    }


    giveGradePoints = () => {
        this.setState({
            isLoading:true,
        })
        // console.log("***" + this.state.grade);
        if(this.state.school > 0)
        {
            // console.log("***" + this.state.grade);
            if(this.state.grade > 0)
            {
                let points = document.getElementById('pointIdSelect').value;
                fetch('https://api.involvedk12.org/api/updateGroupPoints.php?school=' + this.state.school + '&grade=' + this.state.grade + '&points=' + points).then(result => {
                    console.log(result);
                    this.setStudents(this.state.course);
                    this.setState({
                        isLoading:false,
                    });
                });           
            }else{
                alert("Please Select A Grade");
            }
        }else{
            alert("Please Select A School");
        }
    }
    
    giveCoursePoints = () => {
        this.setState({
            isLoading:true,
        })
        if(this.state.course < -1)
        {
            let points = document.getElementById('pointIdSelect').value;
            fetch('https://api.involvedk12.org/api/updateGroupPoints.php?course=' + this.state.course + '&points=' + points).then(result => {
                console.log(result);
                this.setStudents(this.state.course);
                this.setState({
                    isLoading:false,
                });
            });                
        }else{
            alert("Please Select A Course");
        }
    }

    addGradePicker = () => {
        if (this.props.auth === 97) {
            return <GradePicker grade={this.state.grade} setGrade={this.setGrade}>{this.props.children}</GradePicker>;
        }
    }

    setSchool = () => {
        let holdschool = document.getElementById("School");
        let outschool = holdschool[holdschool.selectedIndex].id;
        this.props.setSchool(outschool);
        this.setState({
            school:outschool,
        },() => {
            this.updateCourses(99);
        })
    }

    addSchoolPicker = () => {
        if (this.props.auth === 97) {
            return <SelectComp id='School' options={this.state.schoolOptions} passedOnChange={this.setSchool}></SelectComp>;
        }
    }

    updateStudents = e => {
        const student = parseInt(e.target.id);
        const array = [...this.state.selectedStudents];
        if (e.target.checked) {
            array.push(student);
            this.setState({
                selectedStudents: array,
            })
        } else {
            const array = [...this.state.selectedStudents];
            let deleteIndex = -1;
            for (let index = 0; index < this.state.selectedStudents.length; index++) {
                if (this.state.selectedStudents[index] === student) {
                    deleteIndex = index;
                    break;
                }
            }
            array.splice(deleteIndex, 1);

            this.setState({
                selectedStudents: array,
            });
        }
        console.log(this.state);
    }

    checkAll = () => {
        const array = [];
        this.state.studentsArr.forEach(student => {
            array.push(student.id);
        });
        console.log(array);
        this.setState({
            selectedStudents: array,
        })
        console.log(this.state);
    }

    uncheckAll = () => {
        this.setState({
            selectedStudents: [],
        })
        console.log(this.state);
    }

    render() {

        let studentField;
        if(this.state.isLoading){
            studentField = (<div className="loading-screen">
                <img src="./involved-logo.png" alt="involved-logo" className="signin-logo" />  
                <ReactLoading className="loading-bars" type={"bars"} color="grey" />
            </div>);
        }else{
            studentField = (
                <StudentField checkAll={this.checkAll} uncheckAll={this.uncheckAll} updateStudents={this.updateStudents} selectedStudents={this.state.selectedStudents} students={this.state.studentsArr}>{this.props.children}</StudentField>
            );
        }

        const multiplesInDiv = (
            <div className='pointgiver'>
                {/* <div className='pointGiverDropdownb'>
                    <button>Give Points to School</button>
                </div>
                <div className='pointGiverDropdownb'>
                    <button>Give Points to Grade</button>
                </div>
                <div className='pointGiverDropdownb'>
                    <button>Give Points to Course</button>
                </div>
                <div className='pointGiverDropdownb'>
                    {this.addSchoolPicker()}
                </div>
                <div className='pointGiverDropdownb'>
                    {this.addGradePicker()}
                </div>
                <div className='pointGiverDropdownb'>
                    <ClassDropdown course={this.state.course} setCourse={this.setCourse} courses={this.state.courses}>{this.props.children}</ClassDropdown>
                </div>
                <div className='pointGiverDropdownb'>
                    <PointPicker points={this.state.point} setPoints={this.setPoints} classId={this.state.course} school={this.state.school}>{this.props.children}</PointPicker>
                </div>
                <div className='pointGiverDropdownb'>
                    <button onClick={this.giveThePoints}>Grant Points!</button>
                </div> */}
                <table>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td>
                    {this.addSchoolPicker()}</td>
                            <td>
                    {this.addGradePicker()}</td>
                            <td><ClassDropdown course={this.state.course} setCourse={this.setCourse} courses={this.state.courses}>{this.props.children}</ClassDropdown></td>
                    <td><PointPicker points={this.state.point} setPoints={this.setPoints} classId={this.state.course} school={this.state.school}>{this.props.children}</PointPicker></td>
                    </tr>
                        <tr>
                            <td>
                    <button className={(this.props.auth === 52?("invisible"):("visible"))} onClick={this.giveSchoolPoints}>Grant School Points</button></td>
                            <td>
                    <button className={(this.props.auth === 52?("invisible"):("visible"))} onClick={this.giveGradePoints}>Grant Grade Points</button></td>
                            <td>
                    <button onClick={this.giveCoursePoints}>Grant Course Points</button></td>
                    <td>
                    <button onClick={this.giveThePoints}>Grant Individuals Points!</button></td>
                        
                        </tr>
                    </tbody>
                    <tfooter></tfooter>
                </table>
                <div className='scrollfield'>
                    {studentField}                        
                </div>
            </div>
        );
        return multiplesInDiv;
    }
}


class SelectComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <select id={this.props.id} onChange={this.props.passedOnChange}>
                <option defaultValue disabled>Please Select A {this.props.id}</option>
                {this.props.options.map(opt => (
                    <option id={opt.id} key={opt.id + opt.name} >{opt.name}</option>
                ))}
            </select>
        )
    }
}


export default PointGiver;