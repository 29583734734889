import React from 'react';

class PointBanner extends React.Component {
    render() {
        const multiplesInDiv = (
            <div className='banner'>
                <h1>You have {this.props.points} Points! {100 - this.props.points % 100} left till your next prize!</h1>
            </div>
        );

        return multiplesInDiv;
    }
}

export default PointBanner;