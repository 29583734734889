import React from 'react';
import GradePicker from './gradepicker.js';

import ClassDropdown from "./classdropdown";
import StudentField from "./studentfield";


class ClassManager extends React.Component {

    constructor(props) {
        super(props);

        // this.setGrade = this.setGrade.bind(this);
        // this.setSchool = this.setSchool.bind(this);
        // this.setCourse = this.setCourse.bind(this);
        // this.setTeacher = this.setTeacher.bind(this);
        

        this.state = {
            auth: 97,
            school: 0,
            teacher: 0,
            grade: 0,
            course: 0,
            schools: [],
            courses: [],
            teachers: [],
            students: [],
            selectedStudents: [],
        }
    }

    componentDidMount = () => {
        this.udpateSchools();
    }

    setTeacher = e =>
    {
        const teacherIn = parseInt(e.target.value);
        this.setState({
            teacher: teacherIn,
        });
    }

    setGrade = e => {
        const inGrade = parseInt(e.target.value);
        this.setState({
            grade: inGrade,
        });

        this.setStudentArray(this.state.school,inGrade);
        this.getCourses(this.state.school,inGrade);
        this.updateTeachers(this.state.school,inGrade);
    }

    setSchool = e => {
        const inSchool = parseInt(e.target.value);
        this.setState({
            school: inSchool,
        });
        this.setStudentArray(inSchool,this.state.grade);
        this.getCourses(inSchool,this.state.grade);
        this.updateTeachers(inSchool,this.state.grade);
    }

    setCourse = e => {
        const inCourse = parseInt(e.target.value);
        this.setState({
            course: inCourse,
        });
        this.setStudents(inCourse);
    }

    getCourses = (school,grade) => {
        fetch('https://api.involvedk12.org/api/getClasses.php?school=' + school + '&grade=' + grade)
            .then(res => res.json())
            .then(result => {
                this.setState({
                    courses: result,
                    selectedStudents: [],
                })
            });
    }

    setStudents = (passedValue) => {
        fetch('https://api.involvedk12.org/api/getStudents.php?class=' + passedValue)
            .then(res => res.json())
            .then(result => {
                const passedResult = [];
                result.forEach(student => {
                    passedResult.push(student.id);
                });
                this.setState({
                    selectedStudents: passedResult,
                })
            })
    }

    setStudentArray = (schoolIn,gradeIn) => {
        fetch('https://api.involvedk12.org/api/getStudents.php?school=' + schoolIn + "&grade=" + gradeIn)
            .then(res => res.json())
            .then(result => {
                this.setState({
                    students: result,
                })
            });
    }

    setStudentsInCourse = () => {
        let students = JSON.stringify(this.state.selectedStudents);
        fetch('https://api.involvedk12.org/api/setCourseStudents.php?students=' + students + "&course=" + this.state.course)
            .then(alert("Updated!"));
    }

    updateStudents = e => {
        const student = parseInt(e.target.id);
        const array = [...this.state.selectedStudents];
        if (e.target.checked) {
            array.push(student);
            this.setState({
                selectedStudents: array,
            })
        } else {
            const array = [...this.state.selectedStudents];
            let deleteIndex = -1;
            for (let index = 0; index < this.state.selectedStudents.length; index++) {
                if (this.state.selectedStudents[index] === student) {
                    deleteIndex = index;
                    break;
                }
            }
            array.splice(deleteIndex, 1);

            this.setState({
                selectedStudents: array,
            });
        }
    }


    checkAll = () => {
        const array = [];
        this.state.students.forEach(student => {
            array.push(student.id);
        });
        this.setState({
            selectedStudents: array,
        })
    }
    uncheckAll = () =>{
        this.setState({
            selectedStudents: [],
        })
    }



    addSchool = () =>{
        const newSchool = document.getElementById('newSchoolName').value;
        if(newSchool === '')
        {
            alert("please enter a school name");
        }else{
            fetch('https://api.involvedk12.org/api/addSchool.php?school=' + newSchool)
            .then(document.getElementById('newSchoolName').value = '')
            .then(this.udpateSchools()).then(alert("Added!"));
        }
    }



    addCourse = () =>{
        const newName = document.getElementById('newCourseName').value;
        let msg = '';

        if(this.state.school === 0)
        {
            msg += "A School\n";
        }

        if(this.state.grade === 0)
        {
            msg += "A Grade\n";
        }

        if(this.state.teacher === 0)
        {
            msg += "A Teacher\n";
        }

        if(newName === '')
        {
            msg += "A Course Name\n";
        }

        if(msg === '')
        {
            fetch('https://api.involvedk12.org/api/addCourse.php?school=' + this.state.school + "&course=" + newName + "&teacher=" + this.state.teacher)
            .then(this.getCourses(this.state.school,this.state.grade)).then(alert("Added!"));
        }else{
            alert("Please Select:\n" + msg);
        }
        
        document.getElementById('newCourseName').value = '';
        this.getCourses(this.state.school,this.state.grade);
    }


    updateTeachers = (school,grade) => {
        fetch('https://api.involvedk12.org/api/getTeachers.php?school=' + school + "&grade=" + grade)
        .then(res => res.json())
        .then((result)=>{
            this.setState({
                teachers: result,
            })
        });
    }

    udpateSchools = () => {
        fetch('https://api.involvedk12.org/api/getSchools.php').then(res => res.json()).then((result) => {
            this.setState({
                schools: result,
            })
        });
    }
    
    deleteCourse = e => {
        const courseId = JSON.stringify({courseId: parseInt(e.target.id)});

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: courseId,};

        fetch('https://api.involvedk12.org/api/deleteCourse.php',requestOptions)
        .then(this.getCourses(this.state.school,this.state.grade))
        .then(alert('Deleted.'));
    }

    deleteSchool = e => {
        const school = e.target.id;
        fetch('https://api.involvedk12.org/api/deleteSchool.php?school=' + school)
        .then(this.udpateSchools())
        .then(alert('Deleted.'));
    }

    showSchool = () => {
            document.getElementById("schoolRow").style.display = "";
            document.getElementById("teacherRow").style.display = "none";
            document.getElementById("courseRow").style.display = "none";
            document.getElementById("studentsRow").style.display = "none";
            document.getElementById("courseDropdown").style.display = "none";
            document.getElementById("teacherDropdown").style.display = "none";
            document.getElementById("coursesField").style.display = "none";
            document.getElementById("schoolsField").style.display = "";
    }

    showCourse = () => {
            document.getElementById("courseRow").style.display = "";
            document.getElementById("studentsRow").style.display = "none";
            document.getElementById("schoolRow").style.display = "none";
            document.getElementById("courseDropdown").style.display = "none";
            document.getElementById("teacherDropdown").style.display = "";
            document.getElementById("schoolsField").style.display = "none";
            document.getElementById("coursesField").style.display = "";
            document.getElementById("teacherRow").style.display = "";
    }

    showTeacher = () => {
            document.getElementById("courseRow").style.display = "";
            document.getElementById("studentsRow").style.display = "";
            document.getElementById("schoolRow").style.display = "none";
            document.getElementById("courseDropdown").style.display = "";
            document.getElementById("teacherDropdown").style.display = "none";
            document.getElementById("schoolsField").style.display = "none";
            document.getElementById("coursesField").style.display = "none";
            document.getElementById("teacherRow").style.display = "none";
    }

    updateSchool = (e) => {
        const schoolId = e.target.id.substring(5);
        const school = document.getElementById(e.target.name).value;
        console.log(schoolId + "|" + school);
        fetch('https://api.involvedk12.org/api/updateSchool.php?school=' + school + "&id=" + schoolId)
        .then(this.udpateSchools())
        .then(alert('Updated.'));
    }

    updateCourse = (e) => {
        const courseId = e.target.id.substring(5);
        const course = document.getElementById(e.target.name).value;
        console.log(courseId + "|" + course);
        fetch('https://api.involvedk12.org/api/updateCourse.php?course=' + course + "&courseId=" + courseId)
        .then(this.udpateSchools())
        .then(alert('Updated.'));
    }



    render() {
        const multiplesInDiv = (
            <div className='classManager'>
                <table><thead></thead><tbody>
                <tr><td><button onClick={this.showSchool}>School</button></td>
                <td><button onClick={this.showCourse}>Courses</button></td>
                <td><button onClick={this.showTeacher}>Students</button></td></tr>    
                <tr id="schoolRow">
                    {/* <td>
                    <button onClick={this.deleteCourse} id={this.state.course}>Delete Course</button>
                </td> */}
                <td>
                <input placeholder="New School Name" id='newSchoolName' />
                </td><td>
                <button onClick={this.addSchool}>Add School</button>
                </td></tr>
                <tr id="courseRow" style={{display:"none"}}><td>
                <SchoolSelect setSchool={this.setSchool} schools={this.state.schools} school={this.state.school}>{this.props.children}</SchoolSelect>
                </td><td>
                <GradePicker setGrade={this.setGrade} grade={this.state.grade}>{this.props.children}</GradePicker>
                </td><td id="courseDropdown">
                <ClassDropdown course={this.state.course} setCourse={this.setCourse} courses={this.state.courses}>{this.props.children}</ClassDropdown>
                </td><td id="teacherDropdown">
                <TeacherDropdown teachers={this.state.teachers} udpateTeacher={this.setTeacher} teacher={this.state.teacher} grade={this.state.grade} school={this.state.school}>{this.props.children}</TeacherDropdown>
                </td></tr><tr id="teacherRow" style={{display:"none"}}>
                    {/* <td>
                <TeacherDropdown teachers={this.state.teachers} udpateTeacher={this.setTeacher} teacher={this.state.teacher} grade={this.state.grade} school={this.state.school}>{this.props.children}</TeacherDropdown>
                </td> */}
                <td>
                <input placeholder="New Course Name" id="newCourseName" />
                </td><td>
                <button onClick={this.addCourse}>Add Course</button>
                </td><td></td></tr>
                </tbody><tfoot></tfoot></table>
                <div id="coursesField" style={{display:"none"}}>
                <CoursesField deleteCourse={this.deleteCourse} updateCourse={this.updateCourse} courses={this.state.courses}></CoursesField>
                </div>
                <div id="schoolsField" style={{display:"none"}}>
                <SchoolsField schools={this.state.schools} deleteSchool={this.deleteSchool} updateSchool={this.updateSchool}></SchoolsField>
                </div>
                <div id="studentsRow" style={{display:"none"}}>
                <StudentField checkAll={this.checkAll} uncheckAll={this.uncheckAll} updateStudents={this.updateStudents} selectedStudents={this.state.selectedStudents} students={this.state.students}>{this.props.children}</StudentField>
                <button onClick={this.setStudentsInCourse}>Set Course</button>
                </div>
            </div>
        );

        return multiplesInDiv;
    }
}

class SchoolsField extends React.Component {
    render() {
        const multiplesInDiv = (
            <table key={Math.random(50000)}>
                <thead key={Math.random(50000)}></thead>
                <tbody key={Math.random(50000)}>
                {this.props.schools.map(school => (
                <tr key={Math.random(50000)}>
                    <td key={Math.random(50000)}><input id={"input" + school.id} onChange={console.log(school)} defaultValue={school.name} /></td>
                    <td key={Math.random(50000)}><button id={school.id} onClick={this.props.deleteSchool}>x</button></td>
                    <td key={Math.random(50000)}><button id={"asdfa" + school.id} name={"input" + school.id} onClick={this.props.updateSchool}>Check</button></td>
                </tr>
                ))}
                </tbody>
                <tfoot></tfoot>
            </table>
            );
        return multiplesInDiv;
    }
}

class CoursesField extends React.Component {
    render() {
        const multiplesInDiv = (
            <table key={Math.random(50000)}>
                <thead key={Math.random(50000)}></thead>
                <tbody key={Math.random(50000)}>
                {this.props.courses.map(course => (
                <tr key={Math.random(50000)}>
                    <td key={Math.random(50000)}><input id={"input" + course.id} onChange={console.log(course)} defaultValue={course.class} /></td>
                    <td key={Math.random(50000)}><button id={course.id} onClick={this.props.deleteCourse}>x</button></td>
                    <td key={Math.random(50000)}><button id={"asdfa" + course.id} name={"input" + course.id} onClick={this.props.updateCourse}>Check</button></td>
                </tr>
                ))}
                </tbody>
                <tfoot></tfoot>
            </table>
            );
        return multiplesInDiv;
    }
}

class TeacherDropdown extends React.Component {
    render() {
        const multiplesInDiv = (
            <select id="selectedTeacher" value={this.props.teacher} onChange={this.props.udpateTeacher}>
                <option id={0} value={0}>Please Select A Teacher</option>
                {this.props.teachers.map(teacher => (
                    <option id={teacher.id} value={teacher.id} key={Math.random(50000)}>{teacher.name}</option>
                ))}
            </select>
            );
        return multiplesInDiv;
    }
}


class SchoolSelect extends React.Component {
    render() {
        const multiplesInDiv = (
            <select id='schoolIdSelect' onChange={this.props.setSchool} value={this.props.school}>
                <option id={0} value={0}>Please Select A School</option>
                {this.props.schools.map(school => (
                    <option id={school.id} value={school.id} key={Math.random(50000)}>{school.name}</option>
                ))}
            </select>
        );
        return multiplesInDiv;
    }
}


export default ClassManager;