import React from 'react';



class Prize extends React.Component {

    constructor(props) {
        super(props);
        // Bind the this context to the handler function
        this.state = {
            userId: 0,
            prize: 0,
            arrprize: 0,
            prizes: [{
                id: 1,
                image: "nulled.jpg",
                name: "Unselected"
              }],
        }
        //ToDo: odd issue when reloading, componentDidMount does not load before attempt to push image.
    }

    componentDidMount = () =>
    {
        fetch("https://api.involvedk12.org/api/getPrizes.php").then(res => res.json()).then(result => {
            this.setState({
                prizes:result,
                prize: this.props.prize,
                userId: this.props.userId,
            },() => {
                console.log("*****");
                console.log(result);
                console.log("*****");
            })
        }).then(() => {
            console.log("XXX");
            console.log(this.state);
            let length = this.state.prizes.length;
            for(let i = 0; i < length; ++i)
            {
                if(this.state.prizes[i].id === this.props.prize)
                {
                    this.setState({
                        arrprize: i,
                        prize: this.props.prize,
                    })
                }
            }
        });
    }

    resetToRegular = () => {
        document.getElementById("prizeChangeBlinker").className = "noClassName";
    }

    blinkRed = () => {
        // if(this.state.prize == 0)
        // {
            document.getElementById("prizeChangeBlinker").className = "elementToFadeInAndOutx";
            setTimeout(this.resetToRegular,2000);
        // }
        // ;
    }
    blinkNormal = () => {
        // document.getElementById("prizeChangeBlinker").className = "blinkred";
        // document.getElementById("prizeChangeBlinker").className = "noClassName";
    }

    nextPrize = () => {
        let newPrize = this.state.arrprize - 1;

        if(newPrize < 0)
        {
            newPrize = 0;
        }
        
        const holdPrize = this.setPrize(newPrize);
        //this.setPrize(newPrize);
        console.log("https://api.involvedk12.org/api/updatePrize.php?userId=" + this.props.userId + "&prize=" + holdPrize);
        // fetch("https://api.involvedk12.org/api/updatePrize.php?userId=" + this.props.userId + "&prize=" + holdPrize).then(res => {
            
        // });
        this.blinkRed();//.then(() => this.blinkNormal());
    }


    prevPrize = () => {
        let newPrize = this.state.arrprize + 1;
        console.log(this.state.prizes[1].id);
        console.log(this.state.prizes[this.state.arrprize]);
        if(this.state.prizes[0].id === this.state.prizes[this.state.arrprize].id)
        {
            alert("To Select A Prize Please Click The Image!");
        }

        if(newPrize > this.state.prizes.length - 1)
        {
            newPrize = this.state.prizes.length - 1;
        }
        const holdPrize = this.setPrize(newPrize);
        //this.setPrize(newPrize);
        console.log("https://api.involvedk12.org/api/updatePrize.php?userId=" + this.props.userId + "&prize=" + holdPrize);
        // fetch("https://api.involvedk12.org/api/updatePrize.php?userId=" + this.props.userId + "&prize=" + holdPrize).then(res =>{
        //     //alert(newPrize +  "prize changed" + this.state.userId);
            
        // });
        this.blinkRed();//.then(() => this.blinkNormal());
    }

    setPrize = (inVll) => {
        let newPrize = this.state.prizes[inVll].id;
        console.log(newPrize + " = prize id " + inVll + " = Invil")
        //this.props.updatePrize(inVll);
        this.setState({
            prize: newPrize,
            arrprize: inVll,
        })
        return newPrize;
    }


    resetToHidden = () => {
        document.getElementById("prizeChangeAlert").className = "hidden";
    }
    
    animate = () => {
        console.log("userId=" + this.state.arrprize + "&prize=" + this.state.prize)
        this.props.updatePrize(this.state.prize);
        fetch("https://api.involvedk12.org/api/updatePrize.php?userId=" + this.props.userId + "&prize=" + this.state.prize).then(res =>{
            //alert(newPrize +  "prize changed" + this.state.userId);
            console.log(res)
        }).then(() => {
            this.props.saveState();
        });
        document.getElementById("prizeChangeAlert").className = "elementToFadeInAndOut";
        setTimeout(this.resetToHidden,2000);
    }

    render() {
        const multiplesInDiv = (
            <div style={{width:"100%",height:"100%"}}>
                {/* <h1 style={{margin:"0px"}}>Prize Selected</h1> */}
                <div id="prizeChangeAlert" className="hidden">
                    <p>Prize Changed</p>
                </div>
                <p id="prizeChangeBlinker" className="noClassName">Click Image To Select Prize</p>
                <div className='prizeImage'>
                    <button onClick={this.nextPrize} className='prizePrevButtons'>Prev</button>
                    <img src={"./images/prizes/" + this.state.prizes[this.state.arrprize].image} alt={this.state.prizes[this.state.arrprize].name} onClick={this.animate} />
                    <p onClick={this.animate} className="prizeHint">Click Image To Select A Reward</p>
                    <button onClick={this.prevPrize} className='prizeNextButtons'>Next</button>
                </div>
            </div>
        );

        return multiplesInDiv;
    }
}

export default Prize;