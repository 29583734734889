import React from 'react';


class PointTracker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pointHistory: [],
        }
    }

    componentDidMount = () => {
        fetch('https://api.involvedk12.org/api/getPointHistory.php?userId=' + this.props.userId + "&limit=" + this.props.limit).then(res=>res.json()).then((result) =>{
            console.log(result);
            this.setState({
                pointHistory: result,
            });
        });
    }


    render() {
        const multiplesInDiv = (
            <div className='pointtracker' style={{height:this.props.height}}>
                <table key={Math.random(0,5000)}>
                    <thead></thead>
                    <tbody>
                    <tr><th>Date Awarded</th><th>Activity</th><th>Value</th><th>Description</th></tr>
                    {this.state.pointHistory.map(passed => (
                        <tr className='pointRow' key={Math.random(0,5000)}>
                            <td>
                                {passed.tstamp}
                            </td><td>
                                {passed.activity}
                            </td><td>
                                {passed.points}
                            </td><td>
                                {passed.description}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </div>
        );

        return multiplesInDiv;
    }
}



/**TODO:
 * Date stamp for points earned
 * filter (Close or earned)
 * 
 */

export default PointTracker;