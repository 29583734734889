import React from 'react';

//import PrizeSelect from './prizeselect';

class PrizeAdder extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            prizes:[],
        }
    }
    
    componentDidMount = () =>{
        this.getAvailablePrizes();

    }

    getAvailablePrizes = () => {
        fetch("https://api.involvedk12.org/api/getPrizes.php").then(res => res.json()).then(result => {
            console.log(result);
            this.setState({
                prizes:result,
                file:'',
            })
        });
    }

    deletePrize = e => {
        let delId = e.target.id;
        let id = delId.substr(delId.indexOf(":") + 1);
        fetch("https://api.involvedk12.org/api/deletePrize.php?id=" + id).then(() => {
            this.getAvailablePrizes();
        });
    }

    addPrize = event => {
        let prizeName = document.getElementById("prizename").value;
        let file = document.getElementById("fileToUpload").files[0];
		event.preventDefault();

		let data = new FormData();
		data.append('file', file);//this.state.file);
        data.append('prizename',prizeName);

		fetch('https://api.involvedk12.org/images/prizes/uploadFile.php', {
			method: 'POST',
			body: data
		}).then(() => {alert("Done");}).then(() => {
            this.getAvailablePrizes();
        });
    }

    onFileChange = event => {
		this.setState({
			file: event.target.files[0]
		});

        event.target.ref = "test";
    }

    render() {
        const multiplesInDiv = (
            <div key={Math.random(0,5000)}>
                <div key={Math.random(0,5000)} style={{width:"75%",margin:"0 auto"}}>				
                    <input type='file' name='fileToUpload' id='fileToUpload' />
                    <input type='text' id='prizename' name='prizename' placeholder='Prize Name'/>
                    <button onClick={this.addPrize}>Add Prize</button>
                </div>
                <div key={Math.random(0,5000)}>
                    <table>
                        <tbody>
                        {
                            this.state.prizes.map(prize => {
                                return (
                                    <tr key={Math.random(50000)}>
                                        <td><button id={"prizeAdderId:" + prize.id} onClick={this.deletePrize}>Delete</button></td>
                                        <td>{prize.name}</td>
                                        <td><img alt={prize.name} src={"./images/prizes/" + prize.image} style={{maxWidth:"150px",float:"left"}}/></td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );

        return multiplesInDiv;
    }
}

export default PrizeAdder;