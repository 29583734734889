import React from 'react';

class PrizeGalery extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        prizes:[]
    }
  }

  componentDidMount = () => {
    fetch("https://api.involvedk12.org/api/getPrizes.php").then(res => res.json()).then(result => {
        this.setState({
            prizes:result,
        },() => {
            console.log("*****");
            console.log(result);
            console.log("*****");
        })
    })
  }


  render() {
      const multiplesInDiv = (
        <table>
            <thead></thead>
            <tbody>
                <tr>
                    <td style={{width:"300px"}}><button style={{width:"300px"}}>Back</button></td>
                    <td style={{width:"300px"}}></td>
                </tr>
            {
                this.state.prizes.map(prize => {
                    return (
                        <tr key={Math.random(50000)}>
                            <td key={Math.random(50000)} style={{width:"300px"}}><label key={Math.random(50000)} htmlFor={"image" + prize.name} style={{float:"right"}}>{prize.name}</label></td>
                            <td key={Math.random(50000)} style={{width:"300px"}}><img key={Math.random(50000)} id={"image" + prize.name} style={{width:"300px"}} src={"./images/prizes/" + prize.image} alt={prize.image} /></td>
                        </tr>
                    )
                })
            }
            </tbody>
            <tfoot></tfoot>
        </table>
        );

        return multiplesInDiv;
    }
}
  
export default PrizeGalery;