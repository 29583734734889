import React from 'react';
import {
    Redirect,
} from "react-router-dom";

class Reset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect:false,
        }
    }

    resetPassword = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token');

        let newPass = document.getElementById("newPassword").value;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                token: token,
                pass: newPass
            }),
        };

        fetch("https://api.involvedk12.org/api/resetPassword.php", requestOptions).then(res => res.json()).then(result => {
            if (result.result) {
                alert("Success");
            } else {
                alert("Failed");
            }
        }).then(() => {
            this.setState({
                redirect:true,
            })
        });
    }
    
    handleKeyDown = (e) =>{
        if(e.key === 'Enter')
        {
            document.getElementById('forgotButton').click();
        }

        this.comparePasswords();
    }
    
    comparePasswords = () => {
        var pass = document.getElementById('newPassword').value;
        var passVer = document.getElementById('newPassVer').value;
        if(pass !== passVer)
        {
            document.getElementById('passVerLabel').style.display = "block";
        }else{
            document.getElementById('passVerLabel').style.display = "none";
        }
    }
    

    render() {

        
        if (this.state.redirect) {
            return (<Redirect to={"/signin"} />);
        }

        const multiplesInDiv = (
            <div className="signinForm">
                <table>
                    <thead></thead>
                    <tbody>
                        <tr><td>
                            <h1>Reset Your Password</h1>
                        </td></tr>
                        <tr><td>
                            <label id='passVerLabel' htmlFor='pass' style={{color:"red",display:'none'}}>Passwords Do Not Match</label>
                        </td></tr>
                        <tr><td>
                            <label htmlFor="newPassword" >New Password</label>
                        </td></tr>
                        <tr><td>
                            <input id="newPassword" type="password" placeholder="********" onKeyUp={this.comparePasswords} />
                        </td></tr>
                        <tr><td>
                            <label htmlFor="newPassVer">Verify New Password</label>
                        </td></tr>
                        <tr><td>
                            <input id="newPassVer" type="password" placeholder="********" onKeyUp={this.handleKeyDown} />
                        </td></tr>
                        <tr><td>
                            <button id="forgotButton" onClick={this.resetPassword}>Reset Password</button>
                        </td></tr>
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </div>
        );

        return multiplesInDiv;
    }
}

export default Reset;