import React from 'react';


class PointManager extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            points: [],
            classId: this.props.classId,
            school: this.props.school,
        }
    }

    componentDidMount = () => {
        fetch('https://api.involvedk12.org/api/getPoints.php?ClassId=' + this.state.classId + '&school=' + this.state.school).then(res => res.json()).then((result) => {
            this.setState({
                points:result,
            },() => console.log(result));
        });
    }

    updatePoints = () => {
        fetch('https://api.involvedk12.org/api/getPoints.php?ClassId=' + this.state.classId + '&school=' + this.state.school).then(res => res.json()).then((result) => {
            this.setState({
                points: result,
            },() => {
                console.log(result);
            })
        });
    }

    addPoint = () => {
        let pointValue = document.getElementById('pointValue').value;
        let description = document.getElementById('description').value;
        let activity = document.getElementById('activity').value;
        fetch('https://api.involvedk12.org/api/addPoint.php?points=' + pointValue + '&description=' + description + '&activity=' + activity).then(res => res.json()).then((result) => {
            if (result.message === 'success') {
                this.updatePoints();
                alert("Successfully added " + activity);
            }
        });/** */
    }

    deletePoint = () => {
        let pointId = this.id;
        let activity = this.name;
        alert(pointId + " " + activity);
        fetch('https://api.involvedk12.org/api/deletePoint.php?pointId=' + pointId).then(res => res.json()).then((result) => {
            if (result.message === 'success') {
                this.updatePoints();
                alert("Successfully deleted " + activity);
            }
        });/** */
    }

    render() {


        const multiplesInDiv = (
            <div>
                <div className='smallcard' key={this + 'exField1'}>
                    <h1>Add Points Here</h1>
                    <div className='newPoint'>
                        <table>
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input id='activity' placeholder='Point Name' key={Math.random(0, 5000)} />
                                    </td><td>
                                        <input id='description' placeholder='Point Description' key={Math.random(0, 5000)} />
                                    </td><td>
                                        <input id='pointValue' type='number' placeholder='Point Value' key={Math.random(0, 5000)} />
                                    </td><td>
                                        <button onClick={this.addPoint}>Add Point</button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                    </div>
                </div>
                <div className='smallcard'>
                    <h1>Manage Points Here</h1>

                    <table>
                        <thead></thead>
                        <tbody>
                            {
                                this.state.points.map(point => {
                                    return(
                                    <tr key={Math.random(0, 5000)}>
                                        <td>
                                            <input id={point.id + 1} name={point.id} placeholder={point.activity} key={this + 'input'} />
                                        </td><td>
                                            <input id={point.id + 2} name={point.id} placeholder={point.points} key={this + 'textarea'} />
                                        </td><td>
                                            <input id={point.id + 3} name={point.id} placeholder={point.description} key={this + 'textarea'} />
                                        </td><td>
                                            <button id={point.id} name={point.activity} onClick={e => {
                                                let pointId = e.target.id;
                                                let activity = e.target.name;
                                                alert(pointId + " " + activity);
                                                fetch('https://api.involvedk12.org/api/deletePoint.php?pointId=' + pointId).then(res => res.json()).then((result) => {
                                                    if (result.message === 'success') {
                                                        this.updatePoints();
                                                        alert("Successfully deleted " + activity);
                                                    }
                                                });/** */
                                            }}>Delete</button>
                                        </td>
                                    </tr>)
                                })
                            }

                        </tbody>
                        <tfoot></tfoot>
                    </table>
                </div>
            </div>
        );

        return multiplesInDiv;
    }
}

/**TODO:
 * make sure this is for points and not points....
 */


export default PointManager;