import React from 'react';


class UpdateAccount extends React.Component {

    
    constructor(props) {
        super(props);

        this.state = {
            name:'',
            email:'',
            phone:'',
            userId:0,
        }

        this.state.name = this.props.user.name;
        this.state.email = this.props.user.email;
        this.state.phone = this.props.user.phone;
        this.state.userId = this.props.user.userId;
        console.log(this.props.user);
    }
    

    updateAccountData = () =>
    {
        let phone = document.getElementById('phone').value;
        let name = document.getElementById('name').value;
        let pass = document.getElementById('pass').value;
        let email = document.getElementById('email').value;
        fetch('https://api.involvedk12.org/api/updateUser.php?userId=' + this.state.userId + "&password=" + pass + "&email=" + email + "&name=" + name + "&phone=" + phone);
        alert('Successfully Updated Your Account!');
        this.props.setAccountData(phone,name,pass,email);
    }


    render() {
        const multiplesInDiv = (
            <div className='signinForm'>
                <h1>Account Manager</h1>
                <table>
                    <thead></thead>
                    <tbody>
                    <tr>
                    <td>
                        <label htmlFor='email'>Email</label>
                    </td>
                    <td>
                    <input id='email' name='email' placeholder={this.state.email} />
                    </td>
                </tr><tr>
                    <td>
                        <label htmlFor='pass'>Password</label>
                    </td>
                    <td>
                    <input id='pass' name='pass' type='password' placeholder='Password'></input>
                    </td>
                </tr><tr>
                    <td>
                        <label htmlFor='name'>Name</label>
                    </td>
                    <td>
                    <input id='name' placeholder={this.state.name}></input>
                    </td>
                </tr><tr>
                    <td>
                        <label htmlFor='phone'>Phone</label>
                    </td>
                    <td>
                    <input id='phone' name='phone' placeholder={this.state.phone}></input>
                    </td>
                </tr>
                </tbody>
                <tfoot></tfoot>
                </table>
                <button onClick={this.updateAccountData}>Update</button>
            </div>
        );

        return multiplesInDiv;
    }
}

export default UpdateAccount;