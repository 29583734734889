import React from 'react';
import {
  Redirect,
} from "react-router-dom";
class Quiz extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            quizId: -1,
            questions: [],
            userId: this.props.user.id,
            answers: [],
            blurb: '',
            correct: 0,
            redirect:false,
        }    
        this.state.quizId = this.props.quiz;
        this.state.userId = this.props.user.userId;
    }

    componentDidMount = () => {
        fetch("https://api.involvedk12.org/api/getQuizes.php?quizId=" + this.state.quizId).then(res => res.json())
            .then((result) => {
                this.setState({
                    questions: result.questions,
                    blurb: result.blurb
                });
                console.log(result);
            });
    }

    updateAnswers = e => {
        const id = parseInt(e.target.id.substring(0,e.target.id.indexOf(":")));
        const correct = e.target.id.substring(e.target.id.indexOf(":") + 1);

        const name = e.target.name;
        const value = e.target.value;
        if (value === 'xxRadioxx') {
            let outQuestionIndex = -1;
            for (let index = 0; index < this.state.questions.length; index++) {
                if (this.state.questions[index].questionId === parseInt(name)) {
                    outQuestionIndex = index;
                    break;
                }
            }
            const questions = [...this.state.questions];
            questions[outQuestionIndex].userAnswer = id;
            questions[outQuestionIndex].correct = correct;
            this.setState({
                questions: questions,
            });
            console.log("Radio " + id + " " + name + " " + value + " | " + questions[outQuestionIndex].correct);
        } else {
            let outQuestionIndex = -1;
            for (let index = 0; index < this.state.questions.length; index++) {
                if (this.state.questions[index].questionId === id) {
                    outQuestionIndex = index;
                    break;
                }
            }
            const questions = [...this.state.questions];
            questions[outQuestionIndex].userAnswer = value;
            this.setState({
                questions: questions,
            });
        }

    }


    questionTypeHanler = (question) => {
        if (question.type === 0) {
            return <MultipleChoice question={question} updateAnswers={this.updateAnswers}></MultipleChoice>
        } else {
            return <ShortAnswer question={question} updateAnswers={this.updateAnswers}></ShortAnswer>
        }
    }


    submitCase = () => {
        let len = this.state.questions.length;

        let shrunkQuestions = [...this.state.questions];
        let correctCount = 0;
        let filledIn = 0;
        let lenBal = 0;
        for (var x = 0; x < len; ++x) {
            if(this.state.questions[x].type === 1)
            {
                len = len - 1;
                lenBal = lenBal + 1;
                console.log(this.state.questions[x].userAnswer);
                console.log(typeof this.state.questions[x].userAnswer);
                if(this.state.questions[x].userAnswer === "")
                {
                }else{
                    filledIn = filledIn + 1;
                }
            }else{
                correctCount = correctCount + shrunkQuestions[x].correct;
            }
        }
        console.log(this.state.questions[0]);
        console.log(len + " | " + lenBal +  " | " + correctCount +  " | " + filledIn);
            
        //check if questions are answered correctley
        //dconst correct = e.target.id.substring(e.target.id.indexOf(":") + 1);
        if(len <= correctCount && filledIn === lenBal)
        {
            for (var i = 0; i < len; ++i) {
                shrunkQuestions[i].answers = [];
                shrunkQuestions[i].question = '';
            }

            const stateData = JSON.stringify({
                userId: this.props.user.userId,
                quiz: this.props.user.quiz,
                questions: shrunkQuestions,
            });

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: stateData,
            };

            console.log(stateData);
            fetch("https://api.involvedk12.org/api/submitQuiz.php", requestOptions)
                .then((result) => {
                    alert("Thank you for taking the time to learn about your child's development!");
                    console.log(result);
                })
                .then(() => {
                    this.props.clearQuiz();
                    this.setState({redirect:true});
            });
        }else if(!(len <= correctCount)){
            alert("That was so close, keep trying!");
        }else{
            alert("Please fill in the short answer section");
        }
    }


    render() {
        if(this.state.redirect)
        {
            return (<Redirect to={"/user"} />);
        }
        const multiplesInDiv = (
            <div>
                <p>{this.state.blurb}</p>
                {this.state.questions.map(question => (
                    <div key={Math.random(50000)}>
                        {this.questionTypeHanler(question)}
                    </div>
                ))}
                <button onClick={this.submitCase}>Submit Quiz</button>
            </div>
        );
        return multiplesInDiv;
    }
}



class MultipleChoice extends React.Component {

    selectedHuh = (userAnswer,answerId) => {
        return (userAnswer === answerId)
    }

    render() {
        const multiplesInDiv = (
            <div>
                <p>{this.props.question.question}</p>
                <table style={{display: "table"}}><thead></thead><tbody>
                    {this.props.question.answers.map(answer => (
                        <tr key={Math.random(50000)}><td key={Math.random(50000)} style={{width:"30px"}}>
                            <input type='radio' id={answer.id + ":" + answer.correct} name={this.props.question.questionId} value='xxRadioxx' onChange={this.props.updateAnswers} checked={this.selectedHuh(this.props.question.userAnswer,answer.id)} />
                        </td><td key={Math.random(50000)}>
                            <label htmlFor={answer.id}>{answer.answer}</label>
                        </td></tr>
                    ))}
                </tbody><tfoot></tfoot></table>
            </div>
        );
        return multiplesInDiv;
    }
}

class ShortAnswer extends React.Component {
    render() {

        const multiplesInDiv = (
            <div>
                <p>{this.props.question.question}</p>
                <input id={this.props.question.questionId + ":0"} name={"PullAllShortAnswers"} onBlur={this.props.updateAnswers} />
            </div>
        );

        return multiplesInDiv;
    }
}

export default Quiz;